/* ========= WORDPRESS HOSTING =========== */
.rts-hero {
  &.wp__banner {
    padding: 250px 0 180px;
    & .rts-hero__content{
        text-align: center;
        h1{
            &::before{
                position: unset;
            }
        }
        &--group{
            justify-content: center;
        }
    }
    & .rts-hero__video {
        position: absolute;
        overflow: hidden;
        width: 100%;
        height: 100%;
        top: 0;
        &::before{
            @include ph;
            background: var(--banner-one-bg);
            opacity: .3;
        }
        video {
            min-width: 100%;
            min-height: 100vh;
            z-index: 1;
        }
      }
      
  }
 
}
.rts-contact{
    position: relative;
}
.wp__hosting__option{
    .rts-hosting-type__single::before{
        background: #2d3c58;
    }
    .domain-finder-bg{
        background: #2d3c58 !important;
    }
    .rts-about__image{
        background: #2d3c58 !important;
    }
    .rts-testimonial__single::before{
        background: #2d3c58;
    }
    .primary__bg{
        background: #2d3c58;
        &:hover{
            background: var(--color-third);
        }
    }
}
.map__area{
    position: relative;
    iframe{
        height: 600px;
        width: 100%;
        filter: grayscale(1);
        border-radius: 4px;
    }
}
.contact__form{
    margin-right: 60px;
    &__label{
        color: var(--color-secondary);
        display: inline-block;
        margin-bottom: 15px;
    }
    &__input, &__textarea{
        border-bottom: 1px solid #EAEFFF;;
        padding: 10px 0 !important;
        border-radius: 0;
        font-size: 14px !important;
    }
    &__textarea{
        height: 200px;
        resize: none;
    }
    &__submit{
        display: inline-block;
        background: #2d3c58;
        color: var(--color-white);
        max-width: max-content;
        padding: 15px 25px;
        border-radius: 25px;
        font-weight: 600;
        transition: var(--transition);
        &:hover{
            background: var(--color-third);
            color: var(--color-white);
        }
    }
}
// Hosting Offer
.rts-hosting-banner{
    &.hosting__offer__bg{

    }
}