/*=================Scss Indexing=============
1.variables
2.typography
3.spacing
4.reset
5.forms
6.mixins
7.shortcode
8.animations
9.text-animation
10. site elements
11.header
12.mobile-menu
13.button
14.nav
15.banner
16.swiper
17.funfacts
18.cta
19.about
20.common
21.service
22.projects
23.working-process
24.blog
25.blog-details
26.footer
27.search-input
28.side-bar
29.team
30.testimonials
31.faq
32.pricing
33.date-picker
34.time-picker
35.appoinment
36.awesome-feedback
37.contact
38.pre-loader.scss
39.back-to-top
40. game
41. wordpress



==============================================  */


/* Default  */

@import'default/variables';
@import'default/typography';
@import'default/spacing';
@import'default/reset';
@import'default/forms';
@import'default/mixins';
@import'default/sal';
@import'default/shortcode';
@import'default/animations';
@import'default/text-animation';
@import'default/site-elements';



/* header styles */
@import'header/header';
@import'header/nav';
@import'header/mobile-menu';


/* elements */
@import'elements/common';
@import'elements/button';
@import'elements/banner';
@import'elements/brand';
@import'elements/heading';
@import'elements/homepage-two';
@import'elements/homepage-four';
@import'elements/game';
@import'elements/wordpress';
@import'elements/pricing';
@import'elements/service';
@import'elements/about';
@import'elements/working-process';
@import'elements/counterup';
@import'elements/testimonials';
@import'elements/flash-sell';
@import'elements/blog';
@import'elements/blog-inner';
@import'elements/subscribe';
@import'elements/footer';
@import'elements/social';
@import'elements/back-to-top';
@import'elements/appoinment';
@import'elements/team';
@import'elements/cta';
@import'elements/contact';
@import'elements/seo-optimization';
@import'elements/product';
@import'elements/case-studies';
@import'elements/faq';
@import'elements/feature';
@import'elements/mode-switcher';
@import'elements/index-eleven';





